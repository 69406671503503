@tailwind base;
@tailwind components;
@tailwind utilities;

/* * {
  cursor: none;
} */

@layer base {
	@font-face {
		font-family: 'lead';
		src: url('fonts/Lead/lead-webfont.woff2') format('woff2'),
			url('fonts/Lead/lead-webfont.woff') format('woff');
		font-weight: normal;
		font-style: normal;
	}

	@font-face {
		font-family: 'arastin_pro_free_demoregular';
		src: url('fonts/arastin_pro/arastin_pro-webfont.woff2') format('woff2'),
			url('fonts/arastin_pro/arastin_pro-webfont.woff') format('woff');
		font-weight: normal;
		font-style: normal;
	}

	@font-face {
		font-family: 'arastin_script_free_demoRg';
		src: url('fonts/arastin_pro/arastin_script-webfont.woff2') format('woff2'),
			url('fonts/arastin_pro/arastin_script-webfont.woff') format('woff');
		font-weight: normal;
		font-style: normal;
	}

	@font-face {
		font-family: 'arastin_std_free_demoregular';
		src: url('fonts/arastin_pro/arastin_std-webfont.woff2') format('woff2'),
			url('fonts/arastin_pro/arastin_std-webfont.woff') format('woff');
		font-weight: normal;
		font-style: normal;
	}

	@font-face {
		font-family: 'star_avenueregular';
		src: url('fonts/staravenue/staravenue-webfont.woff2') format('woff2'),
			url('fonts/staravenue/staravenue-webfont.woff') format('woff');
		font-weight: normal;
		font-style: normal;
	}
}
.delay-200 {
	transition-delay: 200ms;
}
.delay-400 {
	transition-delay: 400ms;
}
.delay-600 {
	transition-delay: 600ms;
}
.delay-800 {
	transition-delay: 800ms;
}
.delay-1000 {
	transition-delay: 1000ms;
}
.delay-1200 {
	transition-delay: 1200ms;
}
.delay-1400 {
	transition-delay: 1400ms;
}
.delay-1600 {
	transition-delay: 1600ms;
}
.delay-1800 {
	transition-delay: 1800ms;
}

.delay-2000 {
	transition-delay: 2000ms;
}

.delay-2200 {
	transition-delay: 2200ms;
}
